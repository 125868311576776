import { Image } from "./Image.component";
import { Typography } from "./Typography.component";

import { cn } from "~/lib/utils"

interface BannerProps {
    className?: string;
    children?: React.ReactNode;
    classNameImage?: string;
    srcImage?: string;
    altImage?: string;
    id?: string;
    overlay?: boolean;
    overlayClassName?: string;
    imageWidth?: number;
    imageHeight?: number;
}

export const Banner = (props: BannerProps) => {
    return (
        <figure className={cn("flex flex-col items-start relative overflow-hidden select-none w-full", (!props.imageHeight && !props.imageWidth) ? "h-[380px]" : "", props.className)}>
            <Image 
            src={props.srcImage || ""} 
            alt={props.altImage || ""}
            className={cn("w-full h-full object-center object-cover", props.classNameImage)}
            width={props.imageWidth}
            height={props.imageHeight}
            />
            {props.children}
            {props.overlay && <div className={cn("h-[170%] absolute bottom-0 w-full bg-gradient-to-t from-[rgba(0,0,0,0.7)] via-transparent", props.overlayClassName)}></div>}
        </figure>
    );
}

export const BannerContentDiv = (props: BannerProps) => {
    return (
        <div className={cn("flex flex-wrap w-full my-5", props.className)} id={props.id}>
            {props.children}
        </div>
    );
}

interface BannerSideProps extends  BannerProps {
    title?: string;
    text?: string;
    url?: string;
    width?: string | number;    
    height?: string | number;
    classNameHeadline?: string;
    classNameText?: string;
    rightToLeft?: boolean;
}

const leftSideClasses = "xl:rounded-r-lg xl:rounded-bl-none rounded-b-lg";
const rightSideClasses = "xl:rounded-l-lg xl:rounded-tr-none rounded-t-lg";

export const BannerImageSide = (props: BannerSideProps) => {
    const { rightToLeft=false } = props;
    const classes = rightToLeft ? rightSideClasses : leftSideClasses;

    return (
        <div className={cn("w-full", props.className)}>
            <Image src={props.url?? "/"} width={props.width ?? '100vw'} height={props.height ?? '100vh'} alt="" className={cn("w-full h-[347px] sm:h-full object-cover", classes, props.classNameImage)} />
        </div>
    );
}

export const BannerTextSide = (props: BannerSideProps) => {
    const { rightToLeft=false } = props;
    const classes = !rightToLeft ? rightSideClasses : leftSideClasses;
    return (
        <div className={cn("w-full h-full xl:h-[470px] p-5 flex flex-col justify-center", classes, props.className)}>
            {props.title ? <Typography variant="headline3" type="h2" className={props.classNameHeadline}>{props.title}</Typography> : undefined}
            {props.text ? <Typography variant="body1" className={props.classNameText}>{props.text}</Typography> : undefined}
            {props.children}
        </div>        
    );
}